<template>
  <div class="status-icon" :class="$reconava.getStatusClass(value)">
    <span class="text"> {{ $reconava.getStatusName(value) }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    value: [Number, String],
  },
};
</script>

<style lang="scss" scoped>
.status-icon {
  width: 86px;
  height: 86px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #606266;
  font-size: 20px;

  .text {
    width: 85%;
  }
  &.passed {
    color: $passColor !important;
    border-color: $passColor !important;
  }
  &.rejected {
    color: $rejectColor !important;
    border-color: $rejectColor !important;
  }
  &.repeal {
    color: $repealColor !important;
    border-color: $repealColor !important;
  }
  &.no-arrainged {
    color: $noArraingedColor !important;
    border-color: $noArraingedColor !important;
  }
  &.in-processing {
    color: $subjectColor !important;
    border-color: $subjectColor !important;
  }
  &.to-modify {
    color: $toModifyColor !important;
    border-color: $toModifyColor !important;
  }
  &.termination {
    color: $terminationColor !important;
    border-color: $terminationColor !important;
  }
}
</style>