<template>
  <div class="dynamic-form-detail">
    <template v-for="(item, index) of flattenList">
        <template v-if="item.type != 'qrcode'">
          <div v-if="isShow(item.id) && !item.myType" class="form-item" :key="item.id">
            <span class="label">{{ item.label }}：</span>
            <div class="value">
              <!-- 还有附件的预览，图片的预览，带数据组件的展示 -->
              <template v-if="item.type == 'computed'">
                <computed-parser
                  :value="item"
                  :list="value.form.formSetting"
                ></computed-parser>
              </template>
              <template v-else-if="item.type == 'uploader'&&item.subType == 'attachment'">
                <attachments :fileList.sync="item.value"></attachments>
              </template>
              <template v-else-if="['approve'].includes(item.type)">
                  <approve-parser
                    :value="item"
                    type="check"
                    :approveCode="value.basic.code"
                ></approve-parser>
                </template>
              <template v-else-if="item.type == 'uploader'&&item.subType == 'image'">
                <div class="upload-img">
                  <el-image v-for="img in item.value" style="width: 60px; height: 60px;margin-right: 5px" :src="img.url" :key="img.url" :preview-src-list="[img.url]"></el-image>
                </div>
              </template>
              <template v-else-if="item.type == 'uploader'&&item.subType == 'example'">
                <div class="upload-img">
                  <el-image v-for="img in item.value" style="width: 60px; height: 60px;margin-right: 5px" :src="img.url" :key="img.url" :preview-src-list="[img.url]"></el-image>
                </div>
              </template>
              <template v-else>
                {{ getValue(item) }}
                <capital-text
                    v-if="item.isCapital"
                    :value="item.value"
                    class="capital"
                ></capital-text>
              </template>
            </div>
          </div>
          <template v-if="isShow(item.id) && item.myType">
          <div  class="form-item" v-for="(item2, index2) of item.children" :key="item2.id">
            <span class="label">{{ item2.label }}：</span>
            <div class="value">
              <!-- 还有附件的预览，图片的预览，带数据组件的展示 -->
              <template v-if="item2.type == 'computed'">
                <computed-parser
                  :value="item2"
                  :list="value.form.formSetting"
                ></computed-parser>
              </template>
              <template v-else-if="item2.type == 'uploader'&&item2.subType == 'attachment'">
                <attachments :fileList.sync="item2.value"></attachments>
              </template>
              <template v-else-if="item2.type == 'uploader'&&item2.subType == 'image'">
                <div class="upload-img">
                  <el-image v-for="img in item2.value" style="width: 60px; height: 60px;margin-right: 5px" :src="img.url" :key="img.url" :preview-src-list="[img.url]"></el-image>
                </div>
              </template>
              <template v-else-if="['approve'].includes(item2.type)">
                  <approve-parser
                    :value="item2"
                    type="check"
                ></approve-parser>
                </template>
              <template v-else-if="['multiMonthChildren', 'scheduleChildren', 'refundChildren'].includes(item2.myChildrenType)">
                <template v-if="['activityRefundInfo','refundAccountInfo'].includes(item2.myName)">
                  <table-parser
                    :value="item2"
                ></table-parser>
                </template>
                <template v-else-if="['accountInfo'].includes(item2.myName)">
                  <descriptions-parser
                    :value="item2"
                ></descriptions-parser>
                </template>
                <template v-else>
                  {{ getValue(item2) }}
                  <capital-text v-if="item2.isCapital" :value="item2.value" class="capital"></capital-text>
                </template>
              </template>
              <template v-else>
                {{ getValue(item2) }}
                <capital-text
                    v-if="item2.isCapital"
                    :value="item2.value"
                    class="capital"
                ></capital-text>
              </template>
            </div>
          </div>
          </template>

        </template>
        <template v-if="isShow(item.id) && item.type == 'qrcode'">
          <div class="form-item" :key="index">
            <span class="label">核验时间：</span>
            <div class="value">
              {{showQrcode(item)}}
              <div class="tips" v-if="item.delay">核验时间前后可延长{{item.delay}}分钟</div>
            </div>
          </div>
        </template>
    </template>
    <status v-if="status!=undefined" :value="status" class="status"></status>
  </div>

</template>

<script>
import { flatten } from "./utils/utils.js";
import ComputedParser from "./components/JSONParser/ComputedParser.vue";
import Attachments from "@/pages/businessModule/examine/formDetail/attachments.vue";
import Status from "@/components/status.vue";
import {formatTime, getValueByForm} from "../../pages/businessModule/dynamicForm/utils/utils";
import CapitalText from "./components/JSONParser/CapitalText";
import TableParser from "./components/JSONParser/TableParser";
import DescriptionsParser from "./components/JSONParser/DescriptionsParser";
import ApproveParser from "./components/JSONParser/ApproveParser";
export default {
  name: "DynamicFormDetail",
  components: {CapitalText, ComputedParser, Attachments, Status, DescriptionsParser, TableParser, ApproveParser },
  props: {
    value: Object,
    status: [String, Number],
  },
  data() {
    return {};
  },
  computed: {
    flattenList() {
      const flatten1 = (list) => {
        return list
          .reduce((res, ele) => {
            if (ele.type == "subfield" && !ele.myType) {
              res.push(...ele.children);
            } else {
              res.push(ele);
            }
            return res;
          }, [])
      }
      if (this.value) {
        return flatten1(this.value.form.formSetting);
      } else {
        return [];
      }
    },
    formRelationships() {
      // 用于生成组件间关联关系的表
      // 生成一份映射关系，可以知道每一个表单关联哪一个表单的哪一个选项
      const flatten1 = (list) => {
        return list
          .reduce((res, ele) => {
            if (ele.type == "subfield" && !ele.myType) {
              res.push(...ele.children);
            } else {
              res.push(ele);
            }
            return res;
          }, [])
      }
      const flatList = flatten1(this.value.form.formSetting);
      const res = flatList
        .filter((e) => ["singleSelector", "multipleSelector"].includes(e.type))
        .map((selector) => {
          // 处理选项关联了其他表单之后，表单又被删除的情况
          selector.options.forEach((o) => {
            let res = o.relations.filter((r) =>
              flatList.map((e) => e.id).includes(r)
            );
            if (res.length != o.relations.length) {
              // 避免栈溢出
              o.relations = res;
            }
          });
          return selector;
        })
        .reduce((res, selector) => {
          selector.options.forEach((o) => {
            o.relations.forEach((r) => {
              if (res[r]) {
                if (res[r][selector.id]) {
                  res[r] = {
                    ...res[r],
                    [selector.id]: [...res[r][selector.id], o.id],
                  };
                } else {
                  res[r] = { ...res[r], [selector.id]: [o.id] };
                }
              } else {
                res[r] = { [selector.id]: [o.id] };
              }
            });
          });
          return res;
        }, {});
      // console.log(res);
      return res;
    },
  },
  methods: {
    getComputedValue(sourceValue) {
      const getComputed = (list) => {
        return list.map((ele) => {
          if (/[a-zA-Z]/.test(ele)) {
            const item = flatten(this.value.form.formSetting).find(
              (e) => e.id == ele
            );
            if (item.type == "computed") {
              if (item.id == sourceValue.id) {
                throw new Error("计算公式存在循环引用，无法计算");
              } else {
                return ["(", getComputed(item.value), ")"];
              }
            } else {
              return item.value;
            }
          }
          return ele;
        });
      };
      let res;
      try {
        res = getComputed(sourceValue.value);
        while (res.some((e) => Array.isArray(e))) {
          res = res.flat();
        }
        res = res.map(String);
        try {
          return this.toNormalNum(math.evaluate(res.join("")).toString());
        } catch (error) {
          return "";
        }
      } catch (err) {
        console.error(err);
        return err.message;
      }
    },
    toNormalNum(val) {
      // 转成可读性强的数字
      // TODO 不能使用科学计数法，因为科学计数法没办法转成大写
      const [base, power] = val.split("e");
      if (power) {
        const [symbol, ...powerNum] = power;
        return (
          base +
          "×" +
          (symbol == "+"
            ? `10^${powerNum.join("")}`
            : `10^(-${powerNum.join("")})`)
        );
      } else {
        return base;
      }
    },
    getValue(item) {
      return getValueByForm(item);
    },

    showQrcode(item){
      this.status=='4' && this.$emit('showQrcode',item)
      let start = formatTime(item.value[0], "Y-M-D h:m")
      let end = formatTime(item.value[1], "Y-M-D h:m")
      return `${start}  至  ${end}`
    },

    isShow(id) {
      // 单选和多选的关联
      const getShow = (id) => {
        let dependSelectorsMap = this.formRelationships[id];
        const flatten1 = (list) => {
        return list
          .reduce((res, ele) => {
            if (ele.type == "subfield" && !ele.myType) {
              res.push(...ele.children);
            } else {
              res.push(ele);
            }
            return res;
          }, [])
      }
        if (dependSelectorsMap) {
          return flatten1(this.value.form.formSetting)
            .filter((e) => Object.keys(dependSelectorsMap).includes(e.id)) // 找出所有跟自己关联的选择器对象
            .some((ele) => {
              if (ele.type == "multipleSelector") {
                return (
                  dependSelectorsMap[ele.id].some((e) =>
                      (ele.value||[]).includes(e)
                  ) && getShow(ele.id)
                );
              } else if (ele.type == "singleSelector") {
                return (
                  dependSelectorsMap[ele.id].includes(ele.value) &&
                  getShow(ele.id)
                );
              }
            });
        }
        return true;
      };
      return getShow(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-detail {
  display: flex;
  min-height: 88px;
  flex-direction: column;
  .form-item {
    width: 70%;
    display: flex;
    padding: 8px 0;
    .label {
      width: 150px;
      text-align: right;
      flex-shrink: 0;
    }
    .value {
      width: 100%;
      flex: 1;
      word-break: break-word;
      .attachments{
        width: auto;
        padding: 0;
      }
      .tips{
        color:#9d9d9d;
      }
      .upload-img{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
  .status {
    position: absolute;
    // top: 12px;
    right: 50px;
  }
}
</style>