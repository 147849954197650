import { render, staticRenderFns } from "./TableParser.vue?vue&type=template&id=24219ca0&scoped=true"
import script from "./TableParser.vue?vue&type=script&lang=js"
export * from "./TableParser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24219ca0",
  null
  
)

export default component.exports