<template>
  <div class="dynamic-form-wrapper">
    <div class="detail-info" v-if="detailData">
      <dynamic-form-detail
          :value="detailData.content"
      ></dynamic-form-detail>
    </div>
    <div class="occupy-ele"></div>
    <div class="operations">
      <el-button size="medium" @click="back">返回</el-button>
    </div>
  </div>
</template>

<script>
import DynamicFormDetail from "@/components/dynamicForm/detail";
export default {
  name: "noProcessDetail",
  components: {DynamicFormDetail},
  data(){
    return {
      detailData:null
    }
  },
  created(){
    this.getNoProcessDetail()
  },
  methods:{
    getNoProcessDetail(){
      this.$api.dynamicForm.getFormDetail(this.$route.params.id).then(({ code, data })=>{
        if (code == 200) {
          this.detailData = data;
        }
      })
    },
    back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.dynamic-form-wrapper {

  .detail-info{
    background-color: white;
    padding: 16px;
  }
  .operations {
    width: calc(100% - 204px);
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 204px;
    right: 0;
    background-color: white;
    padding: 12px;
    border-top: 1px solid #e3e3e3;
  }
}
</style>