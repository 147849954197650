<template>
  <div class="refund-account-info">
    <div class="account-info-item">
      <div class="info-label">返款账户名</div>
      <div>{{ value.value.accountName }}</div>
    </div>
    <div class="account-info-item">
      <div class="info-label">返款账户号</div>
      <div>{{ value.value.accountNumber }}</div>
    </div>
    <div class="account-info-item">
      <div class="info-label">开户行</div>
      <div>{{ value.value.bankName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParseDescriptions",
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.refund-account-info {
  background-color: #F2F6FC;
  font-size: 15px;
  padding: 5px 15px;

  .account-info-item {
    height: 25px;
    line-height: 25px;
    display: flex;

    .info-label {
      width: 85px;
      margin-right: 15px;
    }
  }
}
</style>