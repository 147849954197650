<template>
  <div class="approve-parser" v-if="isRelation">
    <div class="approve-item">
      <div><span style="color:red">*</span>活动档期</div>
      <el-select v-model="_modelData.activitySchedules" multiple @change="handelChangeSchedules"
        :disabled="type === 'check'">
        <el-option v-for="(item, index) in  scheduleOptions" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="approve-item">
      <div><span style="color:red">*</span>活动名称</div>
      <el-select v-model="_modelData.activityNames" multiple @change="handelChangeNames" :disabled="type === 'check'">
        <el-option v-for="(item, index) in  nameOptions" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="approve-item">
      <div><span style="color:red">*</span>优惠券</div>
      <el-select v-model="_modelData.coupons" multiple @change="handelChangeCoupons" :disabled="type === 'check'">
        <el-option v-for="(item, index) in  couponOptions" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="approve-item">
      <div>返款明细</div>
      <el-table :data="tableData || []" style="height: auto;" show-summary :summary-method="getSummaries"
        class="approve-table">
        <el-table-column :align="item.align || 'left'" :key="item.prop" :label="item.label" :prop="item.prop"
          :width="item.width || '100'" v-for="(item, index) in columnList">
          <template slot-scope="scope">
            <template v-if="item.prop === 'verifyCount'">
              <el-input-number v-model="scope.row[item.prop]" @change="handleChangeVerifyCount(scope.row)"
                v-if="excludeApprovalId" :controls="false" style="width: 100%;"></el-input-number>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
            <template v-else-if="item.prop === 'feePercent'">
              <span>{{ scope.row['feePercentStr']}}</span>
            </template>
            <div v-else> {{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { uniq, cloneDeep } from 'lodash'

export default {
  name: "ApproveParser",
  props: {
    value: Object,
    list: Array,
    type: String,
    excludeApprovalId: [Number, String],
    approveCode: [Number, String],
  },
  data () {
    return {
      isRelation: false,
      refunds: [], // 初始数据
      scheduleOptions: [],
      nameOptions: [],
      couponOptions: [],
      tableData: [],
      columnList: [
        {
          label: '活动档期',
          prop: 'activityDate'
        },
        {
          label: '活动名称',
          prop: 'activityName'
        },
        {
          label: '券渠道',
          prop: 'channel'
        },
        {
          label: '券名称',
          prop: 'couponName'
        },
        {
          label: '核销数量',
          prop: 'verifyCount'
        },
        {
          label: '商场分摊比例',
          prop: 'marketRatio'
        },
        {
          label: '手续费（%）',
          prop: 'feePercent'
        },
        {
          label: '团购金额',
          prop: 'groupAmount'
        },
        {
          label: '开票金额',
          prop: 'paymentAmount'
        },
        {
          label: '结算金额',
          prop: 'balanceAmount'
        },
      ],
    }
  },
  watch: {
    'value.value': {
      immediate: true,
      deep: true,
      handler (v) {
      }
    }
  },
  computed: {
    _modelData: {
      get () {
        return (
          this.value &&
          this.value.value || {}
        );
      },
      set (val) {
        let res = JSON.parse(JSON.stringify(this.value));
        this.$emit("input", res);
      },
    },
  },
  mounted () {
    this.$bus.$on('changeShop', this.getCouponRefundList)
    if (this.approveCode) {
      this.$api.sysapi.getBusinessListByCode({ code: this.approveCode }).then((res) => {
        if (res.code == 200) {
          let relation = res.data.find(item => item.businessType === 17001)
          this.isRelation = !!relation
          if (!relation) {
            this.goRelation()
          }
        }
      })
    }
    if (this.type === 'check') {
      if (this._modelData && this._modelData.refunds) {
        this.tableData = cloneDeep(this._modelData.refunds)
      }
    } else {
      let refund = this.list.find(item => item.myType === 'refund')
      if (refund && refund.children) {
        let refundShop = refund.children.find(item => item.subType === 'shop')
        if (refundShop && refundShop.value && refundShop.value.value) {
          this.getCouponRefundList(refundShop.value.value, true)
        }
      }
    }

  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (column.property === 'marketRatio') {
          sums[index] = '';
          return;
        }
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = '';
        }
      });
      let feeIndex = columns.findIndex(item => item.property === 'feePercent')
      let newSums = sums.map((item, index) => {
        if (item && !isNaN(item)) {
          if (index === feeIndex) {
            return Number((item * 100).toFixed(6)) + '%'
          } else {
            return Number((item * 1).toFixed(6))
          }
        }
        return item
      })
      return newSums;
    },
    goRelation () {
      this.$confirm('当前流程没有关联优惠券返款业务，是否进行关联?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({ path: '/systemModule/relationForm' })
      }).catch(() => {
        this.$router.go(-1);
      });
    },
    handleChangeVerifyCount (row) {
      let params = [
        {
          couponRefundId: row.id,
          verifyCount: row.verifyCount,
        }
      ]
      this.$api.shopapi.changeCouponRefund(params).then(res => {
        if (res.code == 200) {
          this.changeVerifyCount(cloneDeep(this.refunds), cloneDeep(this.tableData), row, res.data[0])
        } else {
          this.changeVerifyCount(cloneDeep(this.refunds), cloneDeep(this.tableData), row)
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.changeVerifyCount(cloneDeep(this.refunds), cloneDeep(this.tableData), row)
      })
    },
    changeVerifyCount (oldData, newData, row, currentData) {
      let list = cloneDeep(newData)
      let index = list.findIndex(item => item.id === row.id)
      let oldList = cloneDeep(oldData)
      let oldIndex = oldList.findIndex(item => item.id === row.id)
      if (index || index === 0) {
        if (currentData) {
          list[index] = cloneDeep(currentData)
        } else {
          list[index] = cloneDeep(oldList[oldIndex])
        }
      }
      this.getOtherValue(list)
    },
    getCouponRefundList (shopCodeAndNames, flag) {
      const obj = {
        pageSize: -1,
        shopCodeAndNames: shopCodeAndNames,
        inApproval: false,
      }
      if (this.excludeApprovalId) {
        obj.excludeApprovalId = this.excludeApprovalId
      }
      this.$api.shopapi
        .couponRefundList(obj)
        .then(({ data, code }) => {
          if (code == 200) {
            this.refunds = cloneDeep(data.list)
            this.getOtherValue(cloneDeep(data.list), flag)
          } else {
            this.$message.error(msg)
          }
        })
    },
    setOptions (refunds) {
      this.nameOptions = uniq(refunds.filter(item => this._modelData.activitySchedules.includes(item.activityDate)).map(item => item.activityName))
      this.couponOptions = uniq(refunds.filter(item => this._modelData.activitySchedules.includes(item.activityDate) && this._modelData.activityNames.includes(item.activityName)).map(item => item.couponName))
    },
    getOtherValue (refunds, flag) {
      if (refunds) {
        this.scheduleOptions = uniq(refunds.map(item => item.activityDate))
        this.setOptions(refunds)
        if (flag) {
          if (this._modelData && this._modelData.refunds) {
            this.tableData = cloneDeep(this._modelData.refunds)
          }
        } else {
          this.handleFilterRefunds(cloneDeep(refunds))
        }
      }
    },
    handelChangeSchedules () {
      this._modelData.activityNames = []
      this._modelData.coupons = []
      this.nameOptions = []
      this.couponOptions = []
      this.setOptions(this.refunds)
      this.handelChangeRefunds()
    },
    handelChangeNames () {
      this._modelData.coupons = []
      this.couponOptions = []
      this.setOptions(this.refunds)
      this.handelChangeRefunds()
    },
    handelChangeCoupons () {
      this.handelChangeRefunds()
    },
    handelChangeRefunds () {
      let list = cloneDeep(this.refunds)
      let tableData = cloneDeep(this.tableData)
      list.forEach((item, index) => {
        tableData.forEach(ele => {
          if (ele.id === item.id) {
            list[index] = cloneDeep(ele)
          }
        })
      })
      this.handleFilterRefunds(cloneDeep(list))
    },
    handleFilterRefunds (refunds) {
      this.tableData = refunds.filter(item => {
        if (!this._modelData.activitySchedules.length && !this._modelData.activityNames.length && !this._modelData.coupons.length) {
          return true
        }
        return (!this._modelData.activitySchedules.length || this._modelData.activitySchedules.includes(item.activityDate)) && (!this._modelData.activityNames.length || this._modelData.activityNames.includes(item.activityName)) && (!this._modelData.coupons.length || this._modelData.coupons.includes(item.couponName))
      })
      this._modelData.refunds = cloneDeep(this.tableData)
    }

  },
};
</script>

<style lang="scss" scoped>
.approve-parser {
  padding: 8px 16px;

  .approve-item {
    margin-bottom: 10px;
  }
}

::v-deep .approve-table .el-table__footer td .cell {
  font-weight: bold;
  font-size: 14px;
}
</style>